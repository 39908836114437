<!--
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-10-10 09:47:59
 * @LastEditors: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @LastEditTime: 2023-10-17 17:31:57
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\CoworkingCampusOAOAApprovalSubmit\TreeShape\src\components\Optional\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 可选 -->
    <div class="tree-wrapper box">
        <!-- title -->
        <select-title title="选择"></select-title>
        <!-- 搜索 -->
        <select-input
            :dropDownSearchType="dropDownSearchType"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
        >
            <select-option
                :searchDropdownData="searchDropdownData"
                :searchOptionalLoadTitle="searchOptionalLoadTitle"
                @handlerSelectTreeNodeClick="handlerSelectTreeNodeClick"
            ></select-option>
        </select-input>
        <Breadcrumbs
            :breadcrumbsList="breadcrumbsList1"
            @changeTreeListLevel="changeTreeListLevel1"
            :expend.sync="expend"
        />
        <!-- 选择数据 -->   
        <div v-if="!(breadcrumbsList1.length == 1 && treeShowList1 && treeShowList1.length == 0)">
            <div class="tree-option my" v-if="treeShowList1 && treeShowList1.length > 0">
                <div class="all-check" v-if="treeShowList1[0].type !== 'organ' && multiple">
                    <input class="input" type="checkbox" id="all" :checked="allCheck"
                        @click="handlerAllTreeNodeClick1($event)"
                    />
                    <label for="all" class="all-check-label" >全选</label>
                </div>
                <!-- 列表 -->
                <div class="tree-list" id='treeList1'>
                    <div
                        v-for="(item, index) of treeShowList1"
                        :key="index"
                    >
                        <div class="tree-item">
                            <div class="input-item">
                                <div v-if="checkUserOnly">
                                    <input
                                        v-if="item.type !== 'organ'"
                                        class="input"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="item.checked"
                                        @click="handlerTreeNodeClick1($event, item)"
                                    />
                                </div>
                                <div v-else>
                                    <input
                                        v-if="multiple"
                                        class="input"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="item.checked"
                                        @click="handlerTreeNodeClick1($event, item)"
                                    />
                                    <input
                                        v-else-if="!multiple && item.type !== 'organ'"
                                        class="input"
                                        type="checkbox"
                                        :id="item.id"
                                        :checked="item.checked"
                                        @click="handlerTreeNodeClick1($event, item)"
                                    />
                                </div>
                                <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                                    <label class="label" :for="item.id">{{ item.name }}</label>
                                </el-tooltip>
                                <label v-if="item.type === 'organ' && (item.num || item.num === 0)" class="num" :for="item.id">（{{ item.num }}人）</label>
                                <div v-if="item.classFlag == '1' && item.organType == '4'" class="classFlag">班主任</div>
                            </div>
                            <div
                                v-if="
                                    !item.checked &&
                                    !['student', 'teacher'].includes(item.type)
                                "
                                class="tree-next"
                                @click="treeNodeNextLevelClick1(item)"
                            >
                                <img
                                    class="tree-next-img"
                                    :src="treeNextImg"
                                    alt="next"
                                />
                                <span class="tree-next-levels">下级</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="optional-load-title">
                {{ optionalLoadTitle1 }}
            </div>
        </div>
        <div v-else class="optional-load-title" style="font-size:13px;">暂无数据</div>
    </div>
</template>

<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
import SelectInput from "../../components/SelectInput/index.vue";
import SelectOption from "../../components/SelectOption/index.vue";
import Breadcrumbs from "../../components/Breadcrumbs/index.vue";

export default {
    name: "Optional",
    components: {
        SelectTitle,
        SelectInput,
        SelectOption,
        Breadcrumbs,
    },
    data(){
        return {
            expend: true,
        }
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList1: Array,
        breadcrumbsList1: Array,
        optionalLoadTitle1: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    computed: {
        treeNextImg() {
            return require("../../../img/next-levels.png");
        },
    },
    methods: {
        selectFocus() {
            this.$emit("selectFocus");
        },
        selectInput(inputVal) {
            this.$emit("selectInput", inputVal);
        },
        handlerAllTreeNodeClick1(ev) {
            this.$emit("handlerAllTreeNodeClick1", {ev: ev});
        },
        handlerTreeNodeClick(e, item) {
            if (!this.multiple && item.type == 'origin') {
                return;
            }
            this.$emit("handlerTreeNodeClick", {e: e, item: item});
        },
        handlerTreeNodeClick1(e, item) {
            if (!this.multiple && item.type == 'origin') {
                return;
            }
            this.$emit("handlerTreeNodeClick1", {e: e, item: item});
        },
        treeNodeNextLevelClick1(item) {
            this.$emit("treeNodeNextLevelClick1", {treeNode: item});
        },
        changeTreeListLevel1(item) {
            this.$emit("changeTreeListLevel1", {item: item});
        },
        handlerSelectTreeNodeClick (data) {
            const { e, item } = data;
            this.$emit("handlerTreeNodeClick", {e: e, item: item});
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
    height: 570px;
    overflow-y: scroll;
}
.box::-webkit-scrollbar{
    width: 0 !important;
}
.tree-wrapper {
    .tree-option {
        width: 100%;
        .all-check {
            display: flex;
            align-items: center;
            .all-check-label {
                line-height: 20px;
                display: inline-block;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2f33;
                margin-left: 8px;
            }
        }
        .tree-list {
            width: 100%;
            overflow-y: scroll;
            height: 423px;
            .tree-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 8px 16px 8px 0px;
                width: 100%;
                .input-item {
                    width: 84%;
                    display: flex;
                    align-items: center;
                    .label {
                        margin-left: 8px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2b2f33;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .num {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2b2f33;
                        white-space: nowrap;
                    }
                }
                .tree-next {
                    width: 48px;
                    cursor: pointer;
                    white-space: nowrap;
                    .tree-next-img {
                        width: 10px;
                        height: 13px;
                    }
                    .tree-next-levels {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #3C7FFF;
                        margin-left: 6px;
                    }
                }
                .classFlag{
                    border: 1px solid #BCBCBC;
                    color: #BCBCBC;
                    font-size: 13px;
                    border-radius: 4px;
                    padding: 0px 3px;
                    white-space: nowrap;
                    margin-right: 5px;
                }
            }
        }
        .tree-list::-webkit-scrollbar-track{
            background-color: #fff !important;
        }
    }
    .my {
        .tree-list::-webkit-scrollbar-track{
            background-color: #fff !important;
        }
    }
    .optional-load-title {
        font-size: 14px;
        color: #2b2f33;
    }
}
.input {
    width: 18px;
    height: 18px;
}
input[type="checkbox"] {
    visibility: hidden;
    position: relative;
}
//针对火狐浏览器的样式
@media screen and (min--moz-device-pixel-ratio:0) {
	input[type="checkbox"] {
        visibility: visible;
        position: relative;
    }
}
input[type="checkbox"]:after {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    visibility: visible;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e1e3e6;
}
input[type="checkbox"]:checked::after {
    background: #3C7FFF;
}
input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 6px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-right-color: transparent;
    -ms-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
    transform: rotate(-45deg);
    visibility: visible;
    z-index: 1;
    box-sizing: border-box;
}
input[type="checkbox"] {
    content: "";
    display: block;
    background-color: #ffffff;
}
.line{
    width: 290px;
    margin: 0;
    height: 1px;
    background-color: #E7E7E7;
    border: none;
}
</style>
