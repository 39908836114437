<template>
    <!-- 已选 -->
    <div class="select box">
        <div class="select-header">
            <select-title title="已选择"></select-title>
            <!-- <span class="selected-num">{{ selectedNum }}人</span> -->
        </div>
        <div class="select-wrapper">
            <!-- 已选择人员 -->
            <div
                v-for="(item, index) of selectTreeNodeData"
                :key="index"
                class="select-item"
                @click="handlerDelTreeNode($event, item)"
            >
                <div class="select-item-content">
                    <img v-if="['student', 'teacher'].includes(item.type)" :src="personnelImg" alt="personnel">
                    <img v-else :src="departmentImg" alt="department">
                    <span class="title">{{ item.name }}</span>
                    <span v-if="item.type === 'organ' && (item.num || item.num === 0)" class="num">（{{ item.num }}人）</span>
                </div>
                <span class="del-wrapper">
                    <img :src="delImg" alt="del">
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
export default {
    name: "TreeShapeSelected",
    components: {
        SelectTitle
    },
    props: {
        selectTreeNodeData: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
        departmentImg () {
            return require('../../../img/department.png')
        },
        personnelImg () {
            return require('../../../img/personnel.png')
        },
        delImg () {
            return require('../../../img/del.png')
        },
        selectedNum () {
            let num = 0;
            for (let item of this.selectTreeNodeData) {
                if (item.type === 'organ') {
                    if (item.num) num += item.num
                } else {
                    num++
                }
            }
            return num
        }
    },
    methods: {
        handlerDelTreeNode(e, item) {
            this.$emit('handlerDelTreeNode', {e: e, item: item})
        },
    },
};
</script>

<style lang="scss" scoped>
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.select {
    border-left: 1px solid #ddd;
    .select-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 36px;
        .selected-num {
            font-size: 14px;
            font-weight: 400;
            color: #9DA2A6;
        }
    }
    .select-wrapper {
        width: 100%;
        height: 380px;
        overflow: auto;
        .select-item {
            // width: 140px;
            height: 32px;
            margin: 0px 8px 8px 0px;
            box-sizing: border-box;
            padding: 4px 8px 4px 4px;
            font-size: 14px;
            background-color: #F5F5F5;
            border-radius: 3px;
            color: #333;
            display: flex;
            justify-content: space-between;
            float: left;
            .select-item-content {
                display: flex;
                align-items: center;
                .title {
                    max-width: 180px;
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .del-wrapper {
                width: 8px;
                height: 8px;
                cursor: pointer;
                margin-left: 6px;
            }
        }
    }
}
</style>
