<template>
    <div class="dialog-title">
        <h3>{{ title }}</h3>
        <span class="close" @click="closeDialog">
            <img :src="close" alt="close">
        </span>
    </div>
</template>
<script>
export default {
    name: "DialogTitle",
    props: {
        title: {
            type: String
        }
    },
    computed: {
        close () {
            return require('../../../img/close.png')
        }
    },
    methods: {
        closeDialog () {
            this.$emit('closeDialog')
        }
    }
}
</script>
<style lang="scss" scoped>
    .dialog-title {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 40px;
        font-size: 20px;
        background-color: #EDEDED;

        h3 {
            color: #2B2F33;
            font-size: 14px;
            font-weight: bold;
        }
        .close {
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
