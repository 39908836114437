<!--
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-09-21 09:13:54
 * @LastEditors: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @LastEditTime: 2023-09-21 09:50:16
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\CoworkingCampusOAOAApprovalSubmit\TreeShape\src\components\Title\SelectTitle.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <h4 class="select-title">
        {{ title }}
    </h4>
</template>
<script>
export default {
    name: "SelectTitle",
    props: {
        title: {
            type: String
        }
    }
}
</script>
<style lang="scss" scoped>
    .select-title {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        font-weight: 400;
        color: #9DA2A6;
        text-align: left;
    }
</style>
