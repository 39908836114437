<template>
    <div class="select-option">
        <div v-if="searchDropdownData && searchDropdownData.length > 0" class="select-option-wrapper" >
            <div v-for="(item, index) of searchDropdownData" :key="index">
                <div class="tree-item">
                    <div class="input-item" @click.stop="handlerSelectTreeNodeClick($event, item)">
                        <input class="input" type="checkbox" :id="item.id" :checked="item.checked" @input="changeRadio($event,item)"/>
                        <el-tooltip effect="dark" :content="item.name" placement="top" :tabindex="10001">
                            <label class="label" :for="item.id" >{{ item.name }}</label>
                        </el-tooltip>
                        <el-tooltip v-if="item.type != 'organ'" effect="dark" :content="getTipAll(item)" placement="top" :tabindex="10001">
                            <label class="label tip" :for="item.id" >{{getTip(item)}}</label>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div v-else style="font-size:12px;text-align:center;line-height:60px;">
            暂无数据
        </div>
    </div>
</template>
<script>
export default {
    name: "SelectOption",
    data(){
        return {
            gradeLevel:''
        }
    },
    props: {
        searchOptionalLoadTitle: String,
        searchDropdownData: Array,
    },
    methods: {
        handlerSelectTreeNodeClick(e, item) {
            this.$emit("handlerSelectTreeNodeClick", {e: e, item: item});
        },
        getTip(item){
            let arr = []
            switch(this.gradeLevel){
                case '5':
                case '7':
                    arr = [item.gradeName,item.className];
                    break;
                case '6':
                    arr = [item.levelName,item.className];
                    break;
            }
            if((arr[0] + ' ' + arr[1]).length > 10){
                return arr[1]
            }else{
                return arr[0] + ' ' + arr[1]
            }
        },
        getTipAll(item){
            switch(this.gradeLevel){
                case '5':
                    return `${item.gradeName}  ${item.className}`
                case '7':
                    return `${item.levelName} ${item.gradeName}  ${item.className}`
                case '6':
                    return `${item.gradeName} ${item.levelName}  ${item.className}`
            }
        },
        changeRadio(val,item){
            console.log(val.target.checked);
            this.$set(item,'checked',val.target.checked);
            this.$forceUpdate();
        }
    },
    created() {
        this.gradeLevel = this.$store.state.gradeLevel;
    }
};
</script>
<style lang="scss" scoped>
.select-option {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 8;
    width: 100%;
    min-height: 60px;
    max-width: 400px;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    .select-option-wrapper {
        width: 100%;
        max-height: 280px;
        overflow-y: auto;

        .tree-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 8px 16px 8px 7px;
            .input-item {
                display: flex;
                align-items: center;
                width: 100%;
                .label {
                    margin-left: 8px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #2b2f33;
                    cursor: pointer;
                    min-width: 90px;
                    max-width: 110px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .tip{
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    cursor: pointer;
                    margin-left: 10px;
                    max-width: 150px;
                }
            }
        }
    }
    .select-option-wrapper::-webkit-scrollbar{
        width: 0 !important;
    }
}
</style>
